<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md10>
      <material-card
        class="elevation-12 rounded-card"
        title="Finish inbound/Finalizar inbound"
        icon="mdi-pasta"
      >
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Order/Orden:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model.trim="form.order"
                    readonly
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left"
                    >Unloaded pallets/Pallets descargados:</v-subheader
                  >
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.unloadedPallets"
                    :error-messages="unloadedPalletsErrors"
                    @input="$v.form.unloadedPallets.$touch()"
                    @blur="$v.form.unloadedPallets.$touch()"
                  ></integer-input>
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Pallets restack:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.palletsRestack"
                    :error-messages="palletsRestackErrors"
                    @input="$v.form.palletsRestack.$touch()"
                    @blur="$v.form.palletsRestack.$touch()"
                  ></integer-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">QC Inspection:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.qcInspection"
                    :error-messages="qcInspectionErrors"
                    @input="$v.form.qcInspection.$touch()"
                    @blur="$v.form.qcInspection.$touch()"
                  ></integer-input>
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left"
                    >Damaged boxes/Cajas dañadas:</v-subheader
                  >
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.damagedBoxes"
                    :error-messages="damagedBoxesErrors"
                    @input="$v.form.damagedBoxes.$touch()"
                    @blur="$v.form.damagedBoxes.$touch()"
                  ></integer-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left"
                    >Damaged pallets/Tarimas dañadas:</v-subheader
                  >
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.damagedPallets"
                    :error-messages="damagedPalletsErrors"
                    @input="$v.form.damagedPallets.$touch()"
                    @blur="$v.form.damagedPallets.$touch()"
                  ></integer-input>
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Pallets In:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbPalletsIn"
                  ></integer-input>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">PTI:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbPTI"
                  ></integer-input>
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Repack In:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbRepackIn"
                  ></integer-input>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left"
                    >Pallet Replacement:</v-subheader
                  >
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbPalletReplacement"
                  ></integer-input>
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Overtime:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbOvertime"
                  ></integer-input>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Sunday OT:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbSundayOt"
                  ></integer-input>
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Cucumber Repack:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbCucumberRepack"
                  ></integer-input>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">PLU Repack:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbPLURepack"
                  ></integer-input>
                </v-col>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Squash Repack:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbSquashRepack"
                  ></integer-input>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Tomato Repack:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <integer-input
                    :min="0"
                    :allow_negative="false"
                    v-model="form.inbTomatoRepack"
                  ></integer-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="align-center justify-center">
          <v-btn
            class="action"
            color="blue"
            v-on:click.prevent="save"
            :disabled="$v.$invalid"
            :loading="isLoading"
          >
            {{ button }}
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
          <v-btn @click="goBack" color="red">
            Back/Regresar
            <v-icon right>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </material-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import inbound from "@/services/api/inbound";

export default {
  name: "user",
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
    IntegerInput: () => import("@/components/fields/IntegerInput"),
  },
  data() {
    return {
      form: {
        id: null,
        unloadedPallets: 0,
        palletsRestack: 0,
        qcInspection: 0,
        damagedBoxes: 0,
        damagedPallets: 0,
        inbPalletsIn: 0,
        inbPTI: 0,
        inbRepackIn: 0,
        inbPalletReplacement: 0,
        inbOvertime: 0,
        inbSundayOt: 0,
        inbCucumberRepack: 0,
        inbPLURepack: 0,
        inbSquashRepack: 0,
        inbTomatoRepack: 0,
      },
      isCreate: true,
    };
  },
  validations: {
    form: {
      id: {
        required,
      },
      unloadedPallets: {
        required,
      },
      palletsRestack: {
        required,
      },
      qcInspection: {
        required,
      },
      damagedBoxes: {
        required,
      },
      damagedPallets: {
        required,
      },
    },
  },
  async created() {
    if (this.$route.params.status == "6") {
      var currentData = await inbound.getClose(this.$route.params.id);
      this.form = currentData.body;
    }
    this.form.id = this.$route.params.id;
    this.form.order = this.$route.params.order;
    this.form.status = this.$route.params.status;
  },
  methods: {
    async save() {
      try {
        if (!this.$v.$invalid) {
          await inbound.close(this.form);
          const goTo = this.form.status == "6" ? "historic" : "dashboard";
          this.$router.push({
            name: goTo,
          });
        }
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    goBack() {
      this.$router.back();
    },
  },
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    button() {
      return "Close/Cerrar";
    },
    unloadedPalletsErrors() {
      const errors = [];
      if (!this.$v.form.unloadedPallets.$dirty) return errors;
      !this.$v.form.unloadedPallets.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    palletsRestackErrors() {
      const errors = [];
      if (!this.$v.form.palletsRestack.$dirty) return errors;
      !this.$v.form.palletsRestack.required &&
        errors.push("Required/Requerido");
      return errors;
    },
    qcInspectionErrors() {
      const errors = [];
      if (!this.$v.form.qcInspection.$dirty) return errors;
      !this.$v.form.qcInspection.required && errors.push("Required/Requerido");
      return errors;
    },
    damagedBoxesErrors() {
      const errors = [];
      if (!this.$v.form.damagedBoxes.$dirty) return errors;
      !this.$v.form.damagedBoxes.required && errors.push("Required/Requerido");
      return errors;
    },
    damagedPalletsErrors() {
      const errors = [];
      if (!this.$v.form.damagedPallets.$dirty) return errors;
      !this.$v.form.damagedPallets.required &&
        errors.push("Required/Requerido");
      return errors;
    },
  },
};
</script>
